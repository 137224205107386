#ReportContainer {
  margin-top: -5px;
  margin-bottom: 18px;
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  /* justify-content: center; */
}

#ReportContainerOptions {
  display: flex;
  margin-right: 10px;
  padding-right: 5px;
  border-right: 1px solid #cecece;
  align-items: center;
}

.imageButtonReport {
  height: 15px;
  width: 15px;
  fill: white;
}

@media (max-width: 600px) {
  #ReportContainer {
    justify-content: space-between;
    max-width: 100%;
  }

  #ReportContainerOptions {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
  }

  .imageButtonReport {
    height: 20px;
    width: 20px;
  }
}

.report {
  margin-top: -10px;
  height: calc(100vh - 90px);
  width: 100%;
  position: relative;
}

.reportMobile {
  height: calc(100vh - 60px);
  width: 100%;
  padding-left: 1.25rem;
  background-color: white;
}

.topbar {
  height: 30px;
  display: flex;
  align-items: center;
}
.topbar a {
  color: var(--color-white);
  font-size: 18px;
  padding: 0 20px;
}

#overlay {
  width: 100%;
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}

#reportRangeContainer {
  position: fixed;
  bottom: 0;
  height: 30px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  padding-right: 2rem;
}

#reportRange {
  width: 150px;
  margin: 0 5px;
  padding: 0 5px;
  accent-color: #2ecc71
}

#reportRangeValue {
  margin: 0 10px;
}

.zoomReportButton {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.zoomReportButton:hover {
  opacity: 0.5;
  transition: 0.5s;
}

@media (max-width: 600px) {
  #reportRangeContainer {
    padding-right: 1rem;
  }
}